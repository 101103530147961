import { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { TextField } from '@fluentui/react';

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
export default function TabConfig() {
  const [drupalWikiBaseUrl, setDrupalWikiBaseUrl] = useState('');

  useEffect(() => {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.initialize();

    /*
     * After verifying that the settings for your tab are correctly
     * filled in by the user you need to set the state of the dialog
     * to be valid.  This will enable the save button in the configuration
     * dialog.
     */
    microsoftTeams.settings.setValidityState(true);
  });

  useEffect(() => {
    /*
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.settings.setSettings({
        suggestedDisplayName: 'Drupal Wiki',
        entityId: 'drupalWikiTab',
        contentUrl: drupalWikiBaseUrl,
        websiteUrl: drupalWikiBaseUrl,
      });
      saveEvent.notifySuccess();
    });
  }, [drupalWikiBaseUrl]);

  const handleBaseUrlChange = (_event: unknown, newValue?: string) => {
    setDrupalWikiBaseUrl(newValue ?? '');
  };

  return (
    <>
      <TextField
        onChange={handleBaseUrlChange}
        label="Your Drupal Wiki URL"
        placeholder="https://your.drupal-wiki.net"
      />
    </>
  );
}
