import { useEffect } from 'react';
// https://fluentsite.z22.web.core.windows.net/quick-start
import { ThemeProvider } from '@fluentui/react';
import { HashRouter as Router, Redirect, Route } from 'react-router-dom';
import * as msTeams from '@microsoft/teams-js';
import TabConfig from './TabConfig';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  useEffect(() => {
    msTeams.initialize();
  });

  return (
    <ThemeProvider>
      <Router>
        <Route exact path="/">
          <Redirect to="/config" />
        </Route>
        <>
          <Route exact path="/config" component={TabConfig} />
        </>
      </Router>
    </ThemeProvider>
  );
}
